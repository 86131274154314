/* eslint-disable max-lines */
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import * as mitekScienceSDK from '../../../../public/lib/mitek-science-sdk';
import {
  IFrameCaptureResult,
  IComponentPreloadConfig,
  IFrameProcessingFeedback,
} from '../../../../public/lib/types';
import env from '../../../env';
import { IBaseInputComponent } from '../types';
import { AutoScanningContainer } from './components/AutoScanningContainer';
import { DisplayController } from './components/DisplayController';
import { LoadingContainer } from './components/LoadingContainer';
import { ScanningResultContainer } from './components/ScanningResultContainer';
import { ToggleButtons } from './components/ToggleButtons';
import {
  getAutoCaptureOptions,
  getComponentPrealoadConfig,
  getProcessFrameConfig,
  SdkPath,
} from './config';
import {
  DocumentType,
  MitekErrorCode,
  MitekComponentType,
  ScanningStep,
  MimeType,
} from './enums';
import { IState } from './types';
import { getFileExtension } from './utils/getFileExtension';

interface IMitekDocumentOcrContainerValue {
  documentBase64?: string;
  fileType?: string;
}

export type IMitekDocumentOcrContainerProps = IBaseInputComponent<
  null,
  IMitekDocumentOcrContainerValue
>;

const defaultState: IState = {
  step: ScanningStep.SelectProcess,
  errorCode: undefined,
  fileReaderResult: undefined,
};

export const MitekDocumentOcrContainer = ({
  onChange,
  submit,
}: IMitekDocumentOcrContainerProps) => {
  const [state, setState] = useState(defaultState);

  const updateStateWithError = useCallback(
    (errorCode: string) =>
      setState(currentState => ({
        ...currentState,
        errorCode: errorCode as MitekErrorCode,
      })),
    [],
  );

  const updateStateWithCurrentStep = useCallback(
    (step: ScanningStep) =>
      setState(currentState => ({ ...currentState, step })),
    [],
  );

  const handleSubmit = useCallback(() => {
    if (submit) {
      submit();
    }
  }, [submit]);

  const handleOnCancelClick = useCallback(() => {
    mitekScienceSDK.cmd('SDK_REMOVE_LISTENERS');
    mitekScienceSDK.cmd('SDK_STOP');
    updateStateWithCurrentStep(ScanningStep.SelectProcess);
  }, [updateStateWithCurrentStep]);

  const handleOnSdkAutocaptureComplete = useCallback(() => {
    mitekScienceSDK.cmd('SDK_STOP');
  }, []);

  const handleOnUserRetryProcessClick = useCallback(() => {
    updateStateWithCurrentStep(ScanningStep.SelectProcess);
  }, [updateStateWithCurrentStep]);

  const handleOnSdkError = useCallback(
    sdkErrorEvent => updateStateWithError(sdkErrorEvent.code),
    [updateStateWithError],
  );

  const getImageSrcFromOcrResponse = useCallback(response => {
    const { failedImage, imageData } = response.response;
    return (imageData || failedImage) ?? '';
  }, []);

  const handleSdkFrameCaptureResult = useCallback(
    (result: IFrameCaptureResult) => {
      const imageSrc = getImageSrcFromOcrResponse(result);
      const { warnings } = result.response;
      const errorCode = warnings?.key as MitekErrorCode;

      setState(currentState => ({
        ...currentState,
        errorCode,
        fileReaderResult: imageSrc,
        step: ScanningStep.ScanResult,
      }));
    },
    [getImageSrcFromOcrResponse],
  );

  const handleManualFileCaptureResult = useCallback((file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      const fileData = reader?.result;

      // the result type is dependent on the reader function used and could return an ArrayBuffer too
      if (typeof fileData === 'string') {
        setState(currentState => ({
          ...currentState,
          fileReaderResult: fileData,
          step: ScanningStep.ScanResult,
        }));
      }
      reader.onerror = error => {
        throw new Error(`Income document PDF selection failed: ${error}`);
      };
    };
    reader.readAsDataURL(file);
  }, []);

  const handleSdkShowHint = useCallback(
    (message: string, durationMs: number, forceUpdate = false) => {
      mitekScienceSDK.cmd('SHOW_HINT', {
        options: {
          hintText: message,
          hintDuration: durationMs,
          hintForceUpdate: forceUpdate,
        },
      });
    },
    [],
  );

  const handleSdkFrameProcessingFeedback = useCallback(
    (result: IFrameProcessingFeedback) => {
      const { key } = result;
      updateStateWithError(key as MitekErrorCode);
    },
    [updateStateWithError],
  );

  const handleCameraDisplayStarted = useCallback(() => {
    updateStateWithCurrentStep(ScanningStep.AutoScanning);
  }, [updateStateWithCurrentStep]);

  const handleUserStartedCameraCapture = useCallback(() => {
    mitekScienceSDK.on(
      'FRAME_PROCESSING_FEEDBACK',
      handleSdkFrameProcessingFeedback,
    );
  }, [handleSdkFrameProcessingFeedback]);

  const registerEventListeners = useCallback(() => {
    mitekScienceSDK.on('SDK_ERROR', handleOnSdkError);

    // camera started
    mitekScienceSDK.on('CAMERA_DISPLAY_STARTED', handleCameraDisplayStarted);

    // camera finished capturing
    mitekScienceSDK.on('FRAME_CAPTURE_RESULT', handleSdkFrameCaptureResult);

    // Triggers once when frames from live preview that undergo analysis has started. Use as signal for UX.
    mitekScienceSDK.on('FRAME_PROCESSING_STARTED', () => {
      // TODO display a spinner or progress bar
    });
  }, [
    handleCameraDisplayStarted,
    handleSdkFrameCaptureResult,
    handleOnSdkError,
  ]);

  const handleUserAutoCaptureClick = useCallback(() => {
    updateStateWithError('');
    registerEventListeners();

    mitekScienceSDK.cmd('CAPTURE_AND_PROCESS_FRAME', {
      mode: 'AUTO_CAPTURE',
      documentType: DocumentType.Document,
      mitekSDKPath: SdkPath,
      options: getAutoCaptureOptions(env.MISNAP_SDK_LICENSE),
    });

    updateStateWithCurrentStep(ScanningStep.Loading);
  }, [
    registerEventListeners,
    updateStateWithCurrentStep,
    updateStateWithError,
  ]);

  const handleUserFileSelected = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      updateStateWithError('');
      registerEventListeners();

      const file = event.target.files?.[0];
      if (file?.type === MimeType.Pdf) {
        handleManualFileCaptureResult(file);
      } else {
        mitekScienceSDK.cmd(
          'PROCESS_FRAME',
          getProcessFrameConfig(
            file,
            DocumentType.Document,
            env.MISNAP_SDK_LICENSE,
          ),
        );
      }
    },
    [
      handleManualFileCaptureResult,
      registerEventListeners,
      updateStateWithError,
    ],
  );

  useEffect(() => {
    // loads the SDK features required
    const componentPreloadConfig: IComponentPreloadConfig =
      getComponentPrealoadConfig(env.MISNAP_SDK_LICENSE, [
        MitekComponentType.Documents,
      ]);
    mitekScienceSDK.cmd('COMPONENT_PRELOAD', componentPreloadConfig);

    return () => {
      mitekScienceSDK.cmd('SDK_STOP');
      mitekScienceSDK.cmd('SDK_REMOVE_LISTENERS');
    };
  }, []);

  useEffect(() => {
    const { fileReaderResult: imageSrc } = state;
    if (imageSrc) {
      const imageChunks = imageSrc.split(',');
      const fileExtension = getFileExtension(imageChunks[0]);
      const documentBase64 = imageChunks[1];
      onChange({
        documentBase64,
        fileType: fileExtension,
      });
    } else {
      onChange({
        documentBase64: undefined,
        fileType: undefined,
      });
    }
  }, [state, onChange]);

  return (
    <>
      <DisplayController
        state={state}
        visibleForSteps={[ScanningStep.SelectProcess]}
      >
        <ToggleButtons
          onCaptureButtonClick={handleUserAutoCaptureClick}
          onUploadButtonClick={handleUserFileSelected}
        />
      </DisplayController>

      <DisplayController state={state} visibleForSteps={[ScanningStep.Loading]}>
        <LoadingContainer />
      </DisplayController>

      <DisplayController
        state={state}
        visibleForSteps={[ScanningStep.AutoScanning]}
      >
        <AutoScanningContainer
          errorCode={state.errorCode}
          onAutocaptureComplete={handleOnSdkAutocaptureComplete}
          onAutocaptureStarted={handleUserStartedCameraCapture}
          onCloseClick={handleOnCancelClick}
          onShowUserHint={handleSdkShowHint}
        />
      </DisplayController>

      <DisplayController
        state={state}
        visibleForSteps={[ScanningStep.ScanResult]}
      >
        <ScanningResultContainer
          onRetryProcessClick={handleOnUserRetryProcessClick}
          onSubmitClick={handleSubmit}
          state={state}
        />
      </DisplayController>
    </>
  );
};
