import { useCallback, useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import { CircularProgress, IconButton, styled } from '@mui/material';
import { useTranslation, useWindowDimensions } from '@hooks';
import { addStyles } from '@utils';
import { MitekErrorCode } from '../enums';
import {
  calculateA4HeightFromWidth,
  calculateA4WidthFromHeight,
} from '../utils/a4RatioHelpers';
import { getCameraDisplayContainerStyles } from '../utils/getCameraDisplayContainerStyles';
import { getLiveErrorTranslationKey } from '../utils/getErrorTranslationKey';
import { AutoScanningPortal } from './AutoScanningPortal';

const AutocaptureTimeoutMs = 5000;
const AutocaptureFrameWidthPercentageMobile = 0.9;
const AutocaptureFrameHeightPercentageDesktop = 0.65;

const CloseButtonStyled = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
  background-color: #ffffff21;

  &:hover {
    background-color: #ffffff3d;
  }
`;

const CameraActionsContainer = styled('div')`
  position: absolute;
  bottom: 20px;
  text-align: center;
  z-index: 3;
  left: 0;
  right: 0;

  @media (min-width: 1201px) {
    bottom: 60px;
  }
`;

const CountdownContainer = styled('div')`
  display: inline-fled;
  position: relative;

  .MuiCircularProgress-root {
    color: #fff;
    height: 96px !important;
    margin-top: -8px;
    margin-left: -8px;
    width: 96px !important;
  }
`;

const CountdownText = styled('div')`
  color: #fff;
  font-size: 68px;
  font-weight: normal;
  left: -6px;
  position: absolute;
  text-align: center;
  top: 27px;
  width: 100%;
`;

const CameraButtonRing = styled('div')`
  background-color: transparent;
  border-radius: 100%;
  border: 2px solid #fff;
  display: inline-flex;
  height: 80px;
  position: relative;
  width: 80px;

  @media (min-width: 1201px) {
    height: 92px;
    width: 92px;
  }
`;

const CameraButton = styled(IconButton)`
  background-color: white;
  height: 68px;
  margin-left: -74px;
  margin-top: 6px;
  position: absolute;
  width: 68px;

  &:hover {
    background-color: #ffffff91;
  }

  @media (min-width: 1201px) {
    height: 80px;
    margin-left: -86px;
    margin-top: 6px;
    width: 80px;
  }
`;

export interface IAutoScanningContainerProps {
  errorCode?: MitekErrorCode;
  onAutocaptureComplete: () => void;
  onAutocaptureStarted: () => void;
  onCloseClick: () => void;
  onShowUserHint: (
    text: string,
    durationMs: number,
    forceUpdate?: boolean,
  ) => void;
}

export const AutoScanningContainer = ({
  errorCode,
  onAutocaptureComplete,
  onAutocaptureStarted,
  onCloseClick,
  onShowUserHint,
}: IAutoScanningContainerProps) => {
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);
  const [captureTriggered, setCaptureTriggered] = useState(false);
  const secondsRemaining = 5 - Math.floor(progress / 20);
  const { height, width } = useWindowDimensions();

  const handleCaptureTriggeredClick = useCallback(() => {
    onShowUserHint(' ', 1000, true);
    onAutocaptureStarted();
    setCaptureTriggered(true);
  }, [onAutocaptureStarted, onShowUserHint]);

  useEffect(() => {
    if (captureTriggered) {
      const interval = setInterval(() => {
        if (progress * 51 <= AutocaptureTimeoutMs) {
          // eslint-disable-next-line max-nested-callbacks
          setProgress(prev => prev + 2);
        } else {
          clearInterval(interval);
          onAutocaptureComplete();
        }
      }, 100);

      return () => clearInterval(interval);
    }

    return () => {};
  }, [captureTriggered, onAutocaptureComplete, onShowUserHint, progress, t]);

  useEffect(() => {
    const frameHeightDesktop = height * AutocaptureFrameHeightPercentageDesktop;
    const frameWidthDesktop = calculateA4WidthFromHeight(frameHeightDesktop);
    const frameWidthMobile = width * AutocaptureFrameWidthPercentageMobile;
    const frameHeightMobile = calculateA4HeightFromWidth(frameWidthMobile);
    const styles = getCameraDisplayContainerStyles({
      desktopWidth: frameWidthDesktop,
      mobileHeight: frameHeightMobile,
    });
    addStyles(styles);

    if (!captureTriggered) {
      onShowUserHint(
        t('fields.mitekDocumentOcrContainer.autoScanningContainer.introText'),
        100 * 1000,
      );
    }
  }, [captureTriggered, height, onShowUserHint, t, width]);

  useEffect(() => {
    const errorTranslationKey = errorCode
      ? getLiveErrorTranslationKey(errorCode)
      : null;

    if (errorTranslationKey) {
      onShowUserHint(t(errorTranslationKey), 2 * 1000, true);
    }
  }, [errorCode, onShowUserHint, t]);

  return (
    <AutoScanningPortal>
      <CloseButtonStyled aria-label={t('common.close')} onClick={onCloseClick}>
        <Close color="secondary" />
      </CloseButtonStyled>

      <CameraActionsContainer>
        {captureTriggered ? (
          <CountdownContainer>
            <CircularProgress
              aria-valuemax={100}
              aria-valuemin={0}
              aria-valuenow={progress}
              value={progress}
              variant="determinate"
            />
            <CountdownText>{secondsRemaining}</CountdownText>
          </CountdownContainer>
        ) : (
          <>
            <CameraButtonRing />
            <CameraButton
              aria-label={t('common.start')}
              onClick={handleCaptureTriggeredClick}
            />
          </>
        )}
      </CameraActionsContainer>
    </AutoScanningPortal>
  );
};
