import { OriginationChannel, OriginatorName } from '../../../../enums';

const originatorNameToProductNameMap = {
  [OriginatorName.Pagolight]: 'BNPL',
  [OriginatorName.PagolightPro]: 'Finanziamento',
  [OriginatorName.Heidipay]: 'N/A',
};

const originationChannelNameMap = {
  [OriginationChannel.Ecommerce]: 'Ecommerce',
  [OriginationChannel.Instore]: 'Instore / Pay by link',
  [OriginationChannel.Unknown]: 'N/A',
};

export const getItalianProductNameForOriginator = (
  originatorName: OriginatorName,
) => originatorNameToProductNameMap[originatorName];

export const getItalianOriginationChannelName = (
  originationChannel: OriginationChannel,
) => originationChannelNameMap[originationChannel];
