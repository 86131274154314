import { ReactElement } from 'react';
import { ScanningStep } from '../enums';
import { IStepContainerBase } from '../types';

interface IDisplayControllerProps extends IStepContainerBase {
  children: ReactElement;
  visibleForSteps: ScanningStep[];
}

export const DisplayController = ({
  children,
  state,
  visibleForSteps,
}: IDisplayControllerProps) =>
  visibleForSteps.includes(state.step) ? children : null;
