import { Trans } from 'react-i18next';
import { useTranslation } from '@hooks';
import { MerchantRedirectLink } from './MerchantRedirectLink';

interface ITextInputCopyBlock {
  i18nKey: string;
  value?: {
    [key: string]: unknown;
    loginUrl?: string;
  };
}

export const TextInputCopyBlock = ({ i18nKey, value }: ITextInputCopyBlock) => {
  const { t } = useTranslation();

  return (
    <Trans
      components={{
        bold: <b />,
        merchantRedirectLink: <MerchantRedirectLink />,
      }}
      i18nKey={i18nKey}
      t={t}
      values={value}
    />
  );
};
