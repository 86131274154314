import { MitekErrorCode } from '../enums';

const errorCodesMap: { [key in MitekErrorCode]: string } = {
  [MitekErrorCode.MitekErrorFocus]:
    'fields.mitekDocumentOcrContainer.errors.errorFocus',
};

export const getLiveErrorTranslationKey = (errorCode: MitekErrorCode) =>
  errorCodesMap[errorCode] ? `${errorCodesMap[errorCode]}.live` : null;

export const getUploadErrorTranslationKey = (errorCode: MitekErrorCode) =>
  errorCodesMap[errorCode] ? `${errorCodesMap[errorCode]}.upload` : null;
