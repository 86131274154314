import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';

import App from './App';
import env from './env';
import './i18n';
import { getSentryIntegrations } from './utils';
import './polyfills/promiseWithResolvers';

// UNCOMMENT THIS CODE TO ENABLE IN BROWSER MOCKING
// to enable in browser mocking run 'npm run enable:mocking'
// eslint-disable-next-line import/first, import/order
// import { mockCheckoutClient } from './mocking';

// if (process.env.NODE_ENV === 'development') {
//   mockCheckoutClient();
// }

if (env.SENTRY_DSN) {
  Sentry.init({
    dsn: env.SENTRY_DSN,
    environment: env.SENTRY_ENV,
    normalizeDepth: 10,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    integrations: getSentryIntegrations(env.SENTRY_ENV),
    release: env.SENTRY_RELEASE_VERSION,
  });
}

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);
