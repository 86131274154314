import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Link } from '@mui/material';
import { getCancelUrl } from '@redux';

interface IMerchantRedirectLinkProps {
  children?: ReactNode;
}

export const MerchantRedirectLink = ({
  children,
}: IMerchantRedirectLinkProps) => {
  const cancelUrl = useSelector(getCancelUrl);

  return (
    <Link
      fontWeight="bold"
      href={cancelUrl}
      sx={{ cursor: 'pointer' }}
      underline="always"
    >
      {children}
    </Link>
  );
};
