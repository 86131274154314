import { BaseButton } from '@heidi-pay/heidi-component-library/components/Buttons/BaseButton';
import { Box, Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from '@hooks';
import { InternalDocumentViewer } from '@transaction/components/PDFDocumentViewer/components/InternalDocumentViewer';
import { ContinueButtonPortal } from '../../heylight/ContinueButton';
import { MimeType } from '../enums';
import { IState } from '../types';
import { getUploadErrorTranslationKey } from '../utils/getErrorTranslationKey';
import { getFileSrc } from '../utils/getFileSrc';
import { Headline } from './Headline';

const baseTranslationKey =
  'fields.mitekDocumentOcrContainer.scanningResultContainer';

const ImageStyled = styled('img')`
  width: 100%;
  height: auto;
`;

interface IScanningResultContainerProps {
  onRetryProcessClick: () => void;
  onSubmitClick: () => void;
  state: IState;
}

export const ScanningResultContainer = ({
  onRetryProcessClick,
  onSubmitClick,
  state,
}: IScanningResultContainerProps) => {
  const { t } = useTranslation();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const errorTranslationKey = state.errorCode
    ? getUploadErrorTranslationKey(state.errorCode)
    : undefined;
  const canSubmit = !errorTranslationKey && state.fileReaderResult;
  const subtitleKey = errorTranslationKey || `${baseTranslationKey}.subtitle`;
  const { fileReaderResult = '' } = state;
  const isPdfFile = fileReaderResult?.includes(MimeType.Pdf);
  const fileSrc = getFileSrc(fileReaderResult, isPdfFile);

  return (
    <>
      <Headline
        subtitleTranslationKey={subtitleKey}
        titleTranslationKey={`${baseTranslationKey}.title`}
      />
      <Box width="100%">
        {isPdfFile ? (
          <InternalDocumentViewer
            height={isMobile ? '400px' : '600px'}
            uri={fileSrc}
          />
        ) : (
          <ImageStyled
            alt={t(`${baseTranslationKey}.imageAltText`)}
            src={fileSrc}
          />
        )}
      </Box>

      <ContinueButtonPortal>
        <Grid container={true} spacing={2}>
          {canSubmit ? (
            <Grid item={true} xs={12}>
              <BaseButton
                fullWidth={true}
                onClick={onSubmitClick}
                sx={{ textTransform: 'uppercase' }}
                variant="contained"
              >
                {t('fields.mitekDocumentOcrContainer.submitButton.label')}
              </BaseButton>
            </Grid>
          ) : null}
          <Grid item={true} xs={12}>
            <BaseButton
              fullWidth={true}
              onClick={onRetryProcessClick}
              sx={{ borderWidth: '2px', textTransform: 'uppercase' }}
              variant="outlined"
            >
              {t('fields.mitekDocumentOcrContainer.retryButton.label')}
            </BaseButton>
          </Grid>
        </Grid>
      </ContinueButtonPortal>
    </>
  );
};
