import { OriginatorName } from '../enums';
import { pagolightOriginators, pagolightProOriginators } from './constants';

export const getOriginatorFromUuid = (originatorUuid: string) => {
  if (pagolightOriginators.includes(originatorUuid)) {
    return OriginatorName.Pagolight;
  }

  if (pagolightProOriginators.includes(originatorUuid)) {
    return OriginatorName.PagolightPro;
  }

  return OriginatorName.Heidipay;
};
