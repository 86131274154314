import { IRootState } from 'src/store';

export const getCurrentPath = (state: IRootState) =>
  state.router.location.pathname;

export const getIsLoading = (state: IRootState) => state.app.isLoading;

export const getLocales = (state: IRootState) => state.app.locales || [];

export const getCurrentLocale = (state: IRootState) => state.app.locale;

export const getIsUnhandledError = (state: IRootState) =>
  state.app.isUnhandledError;

export const getSoftErrorCode = (state: IRootState) => state.app.softErrorCode;

export const getFallbackUrl = (state: IRootState) => state.app.fallbackUrl;

export const getFailureUrl = (state: IRootState) =>
  state.app.redirectUrls.failure;

export const getSuccessUrl = (state: IRootState) =>
  state.app.redirectUrls.success;

export const getCancelUrl = (state: IRootState) =>
  state.app.redirectUrls.cancel || state.app.redirectUrls.failure;

export const getMetricsData = (state: IRootState) => state.app.metricsData;

export const getOriginationChannel = (state: IRootState) =>
  state.app.originationChannel;

export const getOriginatorUuid = (state: IRootState) =>
  state.app.originatorUuid;

export const getOriginatorName = (state: IRootState) =>
  state.app.originatorName;

export const getMerchantDetails = (state: IRootState) =>
  state.app.merchantDetails;

export const getBrand = (state: IRootState) => state.app.brand;

export const getAbandonVisible = (state: IRootState) =>
  state.app.abandonVisible;

export const getSessionDetails = (state: IRootState) =>
  state.app.sessionDetails;

export const getFeatureFlags = (state: IRootState) => state.app.featureFlags;

export const getExternalContractUuid = (state: IRootState) =>
  state.app.externalContractUuid;

export const getThemeType = (state: IRootState) => state.app.themeType;

export const getIsWhiteLabel = (state: IRootState) => state.app.isWhiteLabel;
