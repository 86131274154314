import { useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Box, Button, ButtonGroup, styled } from '@mui/material';
import { useTranslation } from '@hooks';

const Container = styled(Box)`
  background-color: ${props => props.theme.palette.legacyGrey.light};
  display: flex;
  height: ${props => `${props.height || '400px'}`};
  justify-content: center;
  position: relative;
  text-align: center;
`;

const PDFContainer = styled(Box)`
  height: inherit;
  overflow: scroll;
  padding: 10px 10px 40px 10px;
`;

const ButtonGroupContainer = styled(Box)`
  bottom: 0;
  height: 40px;

  position: absolute;
  > .MuiButtonGroup-root > .MuiButtonBase-root {
    background-color: rgba(0, 0, 0, 0.75);
    border-top-width: 0;
    border-bottom-width: 0;
    color: #fff;
    height: 40px;
    min-height: 40px;

    &:disabled {
      color: #9e9e9e;
    }

    > svg {
      color: inherit;
      height: 24px;
      width: 24px;
    }
  }
`;

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';

export const InternalDocumentViewer = ({ height, uri }) => {
  const maxZoomLevel = 5;

  const [numberOfPages, setNumberOfPages] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(0);
  const containerRef = useRef(null);
  const { t } = useTranslation();

  const handleZoomOut = () => setZoomLevel(prev => (prev > 0 ? prev - 1 : 0));

  const handleZoomIn = () =>
    setZoomLevel(prev => (prev < maxZoomLevel ? prev + 1 : maxZoomLevel));

  const getWidthWithZoom = width =>
    zoomLevel > 0 ? width * (1 + 0.2 * zoomLevel) : width;

  const handleOpenInNewTab = () => {
    window.open(uri, '_blank');
  };

  return (
    <Container
      aria-describedby="scroll-dialog-description"
      aria-labelledby="scroll-dialog-title"
      height={height}
      ref={containerRef}
    >
      <PDFContainer>
        <Document
          file={uri}
          onLoadSuccess={({ numPages }) => setNumberOfPages(numPages)}
        >
          {Array(...Array(numberOfPages))
            .map((_, i) => i + 1)
            .map(page => (
              <Page
                key={page}
                pageNumber={page}
                width={getWidthWithZoom(
                  containerRef.current
                    ? containerRef.current.offsetWidth - 20
                    : 400,
                )}
              />
            ))}
        </Document>
      </PDFContainer>
      <ButtonGroupContainer>
        <ButtonGroup>
          <Button
            aria-label={t('common.zoomOut')}
            disabled={zoomLevel < 1}
            onClick={handleZoomOut}
          >
            <ZoomOutIcon />
          </Button>
          <Button
            aria-label={t('common.zoomIn')}
            disabled={zoomLevel > maxZoomLevel - 1}
            onClick={handleZoomIn}
          >
            <ZoomInIcon />
          </Button>
          <Button
            aria-label={t('common.openInNewTab')}
            onClick={handleOpenInNewTab}
          >
            <OpenInNewIcon />
          </Button>
        </ButtonGroup>
      </ButtonGroupContainer>
    </Container>
  );
};
