import { Box, Link, Typography, styled } from '@mui/material';

export const BigTitle = (
  <div style={{ fontSize: '1.75em', fontWeight: 'bold' }} />
);

const HighlightStyled = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  textDecoration: 'underline',
  textDecorationColor: theme.palette.primary.main,
}));

export const Highlight = <HighlightStyled />;

export const Hyperlink = <Link underline="hover" />;

export const HyperlinkNoUnderline = <Link underline="none" />;

export const LargeText = <Typography component="span" my={2} variant="body1" />;

export const List = (
  <Typography component="ul" p="0.5rem 0 1rem 1rem" variant="body1" />
);

export const ListIndented = (
  <Typography component="ul" p="0.5rem 0 1rem 1.8rem" variant="body1" />
);

export const ListItem = <Typography component="li" variant="body1" />;

export const Paragraph = <Typography component="p" mt={4} variant="body1" />;

export const ParagraphNoStyling = <Typography component="p" variant="body1" />;

export const SmallText = <Typography sx={{ fontSize: '0.75rem' }} />;

export const TitleCentered = <Box component="h3" m={0} textAlign="center" />;

export const TitleNoMargin = <Box component="h3" m={0} />;

export const Underline = <u />;

// The Trans component will raise an exception if the <br /> element is assigned
// because it's a void element and it should not have children or dangerouslySetInnerHTML
export const Br = <Box component="p" m={0} />;

export const typographyComponents = {
  bigTitle: BigTitle,
  highlight: Highlight,
  hyperlink: Hyperlink,
  hyperlinkNoUnderline: HyperlinkNoUnderline,
  largeText: LargeText,
  list: List,
  listIndented: ListIndented,
  listItem: ListItem,
  paragraph: Paragraph,
  paragraphNoStyling: ParagraphNoStyling,
  smallText: SmallText,
  titleCentered: TitleCentered,
  titleNoMargin: TitleNoMargin,
  underline: Underline,
};
