import { DocumentType, MitekComponentType } from './enums';

export const SdkPath = './lib/mitekSDK/';
export const FirstMessageDuration = 1500;
export const CameraDisplayContainerStyles = `
  body .mitekDisplayContainerFull {
    z-index: 2;
    .mitek-generic-guide {
      transform: translate(-50%, -55%);
      @media (max-width: 600px) {  
        height: 65%;
        width: 90%;
        top: 45%;
      }
      @media (min-width: 601px) {
        height: 65%;
        width: 70%;
        top: 45%;
      }
    }
  }
`;

/* eslint-disable max-len */
export const getAutoCaptureOptions = (license: string) => ({
  qualityPercent: 95,
  glareLevel: 2,
  blurLevel: 3,
  hintFrequencyMS: 1000, // How long the hint message displays (in MS) / note: 2400 recommended for screen readers
  hintAriaLive: 0, // Screen reader setting for hint updates value 0=off, 1=polite, 2=assertive
  faceDetectionLevel: 1, // Determines how strict face detection is. 1=lenient, 2=moderate, 3=strict
  hintMessageSize: 1, // Set the size of the hint message. 1=small, 2=medium, 3=large
  disableSmileDetection: false, // disable smile detection
  disablePerpendicularCapture: true, // This is a boolean value defaults to false if not provided. Forces document to follow device orientation. So Captures happen parallel to the device's longer side.
  videoRecordingEnabled: false, // (optional) record the video session / returned as a dataURL
  audioRecordingEnabled: false, // (optional) also record audio / only applies if videoRecordingEnabled = true)
  videoQuality: 30, // (optional) value between 30 to 100 recommended (normalized bitrate of video / only applies if videoRecordingEnabled = true)
  videoRecordingMessage: 'Recording', // (optionally) override the default "Recording" message / Note: set to "" to disable icon and message
  redactMRZOptionalData: true, // (applicable only on certain NLD passports and Ids with 3 line mrz) it will redact optional data from mrz used for bsn
  license,
});

export const getComponentPrealoadConfig = (
  license: string,
  preloadComponents: MitekComponentType[] = [MitekComponentType.Documents],
) => ({
  mitekSDKPath: SdkPath,
  options: {
    license,
  },
  preloadComponents,
});

export const getProcessFrameConfig = (
  file: File | undefined,
  documentType: DocumentType,
  license: string,
) => ({
  documentType,
  mitekSDKPath: SdkPath,
  mode: 'DIRECT',
  options: {
    frame: file,
    qualityPercent: 70, // value between 60 to 100 for image quality 100 is no compression.
    license,
  },
});
