import { FileExtension, MimeType } from '../enums';

const mimetypeToFieExtensionMap = {
  [MimeType.Jpg]: FileExtension.Jpg,
  [MimeType.Pdf]: FileExtension.Pdf,
  [MimeType.Png]: FileExtension.Png,
};

export const getFileExtension = (input: string) => {
  const fileMimeType = Object.keys(mimetypeToFieExtensionMap).find(mime =>
    input.includes(mime),
  );

  if (!fileMimeType) {
    throw new Error(`Invalid file type selected: ${input}`);
  }
  return mimetypeToFieExtensionMap[fileMimeType as MimeType];
};
