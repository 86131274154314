// Polyfill for environments without Promise.withResolvers
// Required for react-pdf
if (typeof Promise.withResolvers === 'undefined') {
  Promise.withResolvers = () => {
    let resolve;
    let reject;

    const promise = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });

    return { promise, resolve, reject };
  };
}
