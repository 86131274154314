/* eslint-disable max-lines */
import { push, replace } from 'connected-react-router';
import delay from 'delay';
import i18n from 'i18next';
import { createActions } from 'redux-actions';
import * as Sentry from '@sentry/react';

import { getOriginatorFromUuid } from '@utils/getOriginatorFromUuid';
import * as configService from '../services/ConfigService';
import { getParsedThemeTypeOrDefault } from '../theme/config/getParsedThemeTypeOrDefault';
import { getCurrentAction } from '../transaction/redux/selectors';
import {
  actionCreator,
  disableSentryReplay,
  parseLocale,
  setToken,
  SoftErrorKeys,
  formatSentryEventTags,
  SentrySeverity,
  setSentryGlobalScope,
} from '../utils';
import { getPersistedFallbackurl, persistFallbackUrl } from '../utils/fallback';
import {
  getCurrentPath,
  getCurrentLocale,
  getFallbackUrl,
  getFailureUrl,
  getIsLoading,
  getSessionDetails,
} from './selectors';

export const {
  addNetworkDisconnectLogItem,
  setAbandonVisible,
  setBrand,
  setExternalContractUuid,
  setFallbackUrl,
  setFeatureFlags,
  setIsLoading,
  setIsUnhandledError,
  setIsWhiteLabel,
  setMerchantDetails,
  setMetricsData,
  setOriginationChannel,
  setOriginatorName,
  setOriginatorUuid,
  setSessionDetails,
  setSoftErrorCode,
  setThemeType,
  updateLocale,
  updateLocales,
  updateRedirectUrls,
} = createActions({
  SET_IS_LOADING: isLoading => ({ isLoading }),
  SET_IS_WHITE_LABEL: isWhiteLabel => ({ isWhiteLabel }),
  UPDATE_LOCALES: locales => ({ locales }),
  UPDATE_LOCALE: locale => ({ locale }),
  SET_IS_UNHANDLED_ERROR: isUnhandledError => ({ isUnhandledError }),
  SET_SOFT_ERROR_CODE: softErrorCode => ({ softErrorCode }),
  UPDATE_REDIRECT_URLS: redirectUrls => ({ redirectUrls }),
  SET_FALLBACK_URL: fallbackUrl => ({ fallbackUrl }),
  SET_FEATURE_FLAGS: featureFlags => ({ featureFlags }),
  SET_METRICS_DATA: metricsData => ({ metricsData }),
  SET_ORIGINATION_CHANNEL: originationChannel => ({ originationChannel }),
  SET_ORIGINATOR_NAME: originatorName => ({ originatorName }),
  SET_ORIGINATOR_UUID: originatorUuid => ({ originatorUuid }),
  SET_MERCHANT_DETAILS: (
    name,
    logo,
    primaryColour,
    translationOverrides,
    uuid,
  ) => ({
    name,
    logo,
    primaryColour,
    translationOverrides,
    uuid,
  }),
  SET_BRAND: brand => ({ brand }),
  SET_ABANDON_VISIBLE: abandonVisible => ({ abandonVisible }),
  SET_EXTERNAL_CONTRACT_UUID: externalContractUuid => ({
    externalContractUuid,
  }),
  SET_SESSION_DETAILS: ({ checkoutType, sessionUuid }) => ({
    checkoutType,
    sessionUuid,
  }),
  SET_THEME_TYPE: themeType => ({
    themeType,
  }),
  ADD_NETWORK_DISCONNECT_LOG_ITEM: networkDisconnectLogItem => ({
    networkDisconnectLogItem,
  }),
});

const LOADING_STATE_DEBOUNCE_TIMEOUT = 200;
let timeout;

export const setErrorState = actionCreator(
  'setErrorState',
  error => async (dispatch, getState) => {
    const state = getState();

    if (!error.isLoggedUpstream) {
      const tags = formatSentryEventTags(state?.app?.sessionDetails ?? {});
      Sentry.captureException(error, {
        level: SentrySeverity.warning,
        tags,
      });
    }

    if (error?.isLockedSession) {
      window.location.assign('/processing-please-wait');
      return;
    }

    if (error?.softErrorCode) {
      // will trigger an alert in the form
      dispatch(setSoftErrorCode(error.softErrorCode));
    } else {
      // will display the ErrorBackdrop component
      dispatch(setIsUnhandledError(error));
    }
  },
);

export const resetErrorState = actionCreator(
  'resetErrorState',
  () => dispatch => {
    dispatch(setSoftErrorCode(null));
    dispatch(setIsUnhandledError(false));
  },
);

export const setLoadingState = actionCreator(
  'setLoadingState',
  isLoading => (dispatch, getState) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    if (!isLoading && getIsLoading(getState())) {
      timeout = setTimeout(() => {
        dispatch(setIsLoading(isLoading));
      }, LOADING_STATE_DEBOUNCE_TIMEOUT);
    } else {
      dispatch(setIsLoading(isLoading));
    }
  },
);

export const resolveFallbackUrl = actionCreator(
  'resolveFallbackUrl',
  () => dispatch => {
    const urlParams = new URLSearchParams(window.location.search);
    const fallbackUrl = urlParams.get('fallback') || getPersistedFallbackurl();
    if (fallbackUrl) {
      dispatch(setFallbackUrl(fallbackUrl));
      persistFallbackUrl(fallbackUrl);
    }
  },
);

export const updateSessionLocale = actionCreator(
  'updateLocale',
  locale => async dispatch => {
    dispatch(updateLocale(locale));

    const { country, lang } = parseLocale(locale);
    const formatted = `${lang}-${country}`.toLowerCase();
    i18n.changeLanguage(formatted);

    // update the origination session locale on the backend
    // asynchronously
    configService
      .putLocale(locale)
      .catch(error => dispatch(setErrorState(error)));

    return null;
  },
);

export const changeLocale = actionCreator(
  'changeLocale',
  locale => async (dispatch, getState) => {
    const state = getState();
    const currentLocale = getCurrentLocale(state);

    if (locale !== currentLocale) {
      await dispatch(updateSessionLocale(locale));
    }
  },
);

export const updateThemeType = actionCreator(
  'updateThemeType',
  themeType => async dispatch => {
    const parsedTheme = getParsedThemeTypeOrDefault(themeType);
    await dispatch(setThemeType(parsedTheme));
  },
);

export const getMerchantTranslationKeyOverrides = overrides => {
  try {
    return overrides ? JSON.parse(overrides) : {};
  } catch {
    Sentry.captureMessage(
      `Merchant translation key overrides JSON is invalid ${overrides}`,
      SentrySeverity.error,
    );
    return {};
  }
};

export const parseConfig = actionCreator(
  'parseConfig',
  config => async dispatch => {
    const originatorName = getOriginatorFromUuid(config.originatorUuid);
    dispatch(updateLocales(config.availableLocales));
    dispatch(updateRedirectUrls(config.redirectUrls));
    dispatch(setMetricsData(config.metricsData));
    dispatch(setOriginationChannel(config.originationChannel));
    dispatch(setOriginatorUuid(config.originatorUuid));
    dispatch(setOriginatorName(originatorName));
    dispatch(
      setMerchantDetails(
        config.merchantDetails.merchantName,
        config.merchantDetails.merchantImage,
        config.merchantDetails.primaryColour,
        getMerchantTranslationKeyOverrides(
          config.merchantDetails.merchantTranslationKeyOverridesJson,
        ),
        config.merchantDetails.merchantUuid,
      ),
    );
    dispatch(setBrand(config.brand));
    dispatch(setExternalContractUuid(config.externalContractUuid));
    dispatch(setFeatureFlags(config.featureFlags || {}));
    dispatch(setSessionDetails(config));
    dispatch(updateThemeType(config.themeType ?? ''));
    await dispatch(changeLocale(config.locale));
    return null;
  },
);

export const fallback = actionCreator(
  'fallback',
  () => async (dispatch, getState) => {
    const state = getState();
    const fallbackUrl = getFailureUrl(state) || getFallbackUrl(state);

    if (fallbackUrl) {
      await delay(2000);
      window.onbeforeunload = () => {};
      window.location.assign(fallbackUrl);
    }
  },
);

export const exchangeToken = actionCreator(
  'exchangeToken',
  () => async dispatch => {
    const urlParams = new URLSearchParams(window.location.search);
    const otc = urlParams.get('otc');
    const isWhiteLabel = urlParams.get('wl');

    dispatch(setIsWhiteLabel(isWhiteLabel === 'True'));

    if (otc) {
      try {
        dispatch(setLoadingState(true));

        const { config, token } = await configService.getToken(otc);
        setToken(token);
        if (config) {
          dispatch(parseConfig(config));
          const sentryTags = formatSentryEventTags({
            checkoutType: config.checkoutType,
            merchantName: config.merchantDetails?.merchantName,
            originationChannel: config.originationChannel,
            sessionUuid: config.sessionUuid,
          });
          setSentryGlobalScope(sentryTags);
          return config;
        }
      } catch (error) {
        if (error.status === 401 || error.status === 403) {
          error.softErrorCode = SoftErrorKeys.invalidOTC;
        } else {
          dispatch(fallback());
        }
        dispatch(setErrorState(error));
      }
    }
    dispatch(setLoadingState(false));
    return null;
  },
);

export const loadConfig = actionCreator('loadConfig', () => async dispatch => {
  try {
    dispatch(setLoadingState(true));
    const config = await configService.getConfig();
    const sentryTags = formatSentryEventTags({
      checkoutType: config.checkoutType,
      merchantName: config.merchantDetails?.merchantName,
      originationChannel: config.originationChannel,
      sessionUuid: config.sessionUuid,
    });
    setSentryGlobalScope(sentryTags);

    const shouldDisableSentryReplay =
      config.featureFlags?.isSentryReplayEnabled === false;
    if (shouldDisableSentryReplay) {
      disableSentryReplay();
    }

    dispatch(parseConfig(config));
    return config;
  } catch (error) {
    dispatch(setErrorState(error));
    dispatch(fallback());
  } finally {
    dispatch(setLoadingState(false));
  }

  return null;
});

export const changePath = actionCreator(
  'changePath',
  (path, replacePath = false) =>
    (dispatch, getState) => {
      const state = getState();
      const currentPath = getCurrentPath(state);

      if (path !== currentPath) {
        if (replacePath) {
          dispatch(replace(path));
        } else {
          dispatch(push(path));
        }
      }
    },
);

export const logOfflineEvent = actionCreator(
  'logOfflineEvent',
  () => (dispatch, getState) => {
    const rootState = getState();
    const actionName = getCurrentAction(rootState);
    const { sessionUuid } = getSessionDetails(rootState);

    dispatch(
      addNetworkDisconnectLogItem({
        actionName,
        sessionUuid,
        timestamp: new Date().toString(),
      }),
    );
  },
);
