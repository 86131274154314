import { jwtDecode } from 'jwt-decode';

const minimumValidityMinutes = 5;

export const getExpiryDateForJwtToken = (token: string) => {
  const decodedToken = jwtDecode(token);
  return (decodedToken?.exp ?? 0) * 1000;
};

export const isJwtTokenExpired = (token: string) => {
  try {
    const expiryDateMs = getExpiryDateForJwtToken(token);
    const dateNowMs = Date.now();

    return dateNowMs > expiryDateMs;
  } catch {
    return true;
  }
};

export const shouldRefreshOnfidoJwtToken = (token: string) => {
  try {
    const expiryDateMs = getExpiryDateForJwtToken(token);
    const dateNowMs = Date.now();

    if (dateNowMs >= expiryDateMs) {
      return true;
    }

    const remainingMs = expiryDateMs - dateNowMs;
    const remainingMinutes = remainingMs / (1000 * 60);

    return remainingMinutes <= minimumValidityMinutes;
  } catch {
    return true;
  }
};
