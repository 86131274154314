export enum FieldName {
  AgreementSigningMethod = 'agreementSigningMethod',
  DepositOverrideAmount = 'depositOverrideAmount',
  OrderDiscountAmount = 'orderDiscountAmount',
  PricingStructureCode = 'pricingStructureCode',
}

export enum OriginatorUuidQa {
  HEIDIPAY = 'f93e233b-de32-4750-a021-fc4cd425cb74',
  PAGOLIGHT = '8d2e68ab-9b3c-4d50-bd80-2cc6825d7797',
  PAGOLIGHTPRO = 'a5413f30-a649-4179-86a1-604d308d4219',
}

export enum OriginatorUuidSbx {
  HEIDIPAY = '47258045-9ff1-41e7-a9a2-195073056ac1',
  PAGOLIGHT = '8d8daabb-eef9-4c43-a0a7-e64c611fb271',
  PAGOLIGHTPRO = '42e0be11-e948-43d6-a821-14c18ed90a0c',
}

export enum OriginatorUuidProd {
  HEIDIPAY = '9e2a5d31-1a18-48df-a81b-ec1289eb39bf',
  PAGOLIGHT = '32d9eef9-211c-4539-9bd5-b0dec6c0a5a5',
  PAGOLIGHTPRO = '1dc211bc-67a8-4360-a00d-6cf2c743e225',
}

export enum AddressType {
  BillingAddress = 'billingAddress',
  ShippingAddress = 'shippingAddress',
}

export enum PaymentMethod {
  Card = 'card',
  DirectDebit = 'direct_debit',
  SwissQr = 'swiss_qr',
}

export enum ContractType {
  Factoring = 'Factoring',
  RegulatedCredit = 'regulated credit',
  UnregulatedCredit = 'unregulated credit',
}

export enum OriginatorName {
  Heidipay = 'HEIDIPAY',
  Pagolight = 'PAGOLIGHT',
  PagolightPro = 'PAGOLIGHTPRO',
}

export enum OriginationChannel {
  Ecommerce = 'ecommerce',
  Instore = 'in_store',
  Unknown = 'unknown',
}
