import { MimeType } from '../enums';

function base64ToBlob(base64: string) {
  const byteCharacters = atob(base64);
  const byteArrays = [];
  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteArrays.push(byteCharacters.charCodeAt(i));
  }
  const byteArray = new Uint8Array(byteArrays);
  return new Blob([byteArray], { type: MimeType.Pdf });
}

export const getFileSrc = (fileReaderResult: string, isPdfFile: boolean) => {
  if (isPdfFile && fileReaderResult) {
    const base64File = fileReaderResult.split(',')[1];
    const blob = base64ToBlob(base64File);

    return URL.createObjectURL(blob);
  }

  return fileReaderResult;
};
