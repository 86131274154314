interface IGetCameraDisplayContainerStylesProps {
  desktopWidth: number;
  mobileHeight: number;
}

export const getCameraDisplayContainerStyles = ({
  desktopWidth,
  mobileHeight,
}: IGetCameraDisplayContainerStylesProps) => `
  body .mitekDisplayContainerFull {
    z-index: 2;
    .mitek-generic-guide {
      transform: translate(-50%, -55%);
      @media (max-width: 600px) {  
        height: ${mobileHeight}px;
        width: 90%;
        top: 48%;
      }
      @media (min-width: 601px) {
        height: 65%;
        width: ${desktopWidth}px;
        top: 45%;
      }
    }
  }
`;
